<template>
  <div id="home-container">
    <slot/> 
  </div>
</template>

<script>
// This layout is for the home screen
import { mapGetters } from "vuex";
export default {
  name: "homelayout",    
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#home-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  max-height: 768px;
  z-index: 150;
  background: $ps-room-purple;
  margin: 0 auto;
}
</style>